<template>
  <div class="font-proximaMedium">
    <FormError :errors="errorForm" />
    <div class="w-full flex items-center justify-between">
      <span class="text-subheaderText">Vaccine Type</span>
      <SelectOptions
        class="w-9/12"
        :dataList="vaccineType"
        title="Select type"
        @selected="vaccineValue"
      />
    </div>
    <div class="mt-6 text-left flex flex-col">
      <span class="mb-2 text-subheaderText">Vaccine Name</span>
      <input
        type="text"
        placeholder="Enter brand name of vaccine"
        class="font-proximaLight text-sm p-4 bg-white rounded-md"
        v-model="form.vaccine_name"
      />
    </div>
    <div class="mt-6 text-left">
      <h5 class="mb-2 text-subheaderText">Validity</h5>
      <div class="p-4 bg-white rounded-md">
        <div class="flex flex-col text-left">
          <span class="mb-1 text-subheaderText">Applied</span>
          <date-picker
            type="date"
            format="YYYY-MM-DD"
            v-model="form.date_applied"
            placeholder="Select date"
            class="w-full my-1 text-sm"
          ></date-picker>
        </div>
        <div class="mt-4 flex flex-col text-left">
          <span class="mb-1 text-subheaderText">Expires</span>
          <date-picker
            v-model="form.date_expired"
            type="date"
            :disabled="!form.vaccine_type"
            format="YYYY-MM-DD"
            :placeholder="
              !form.vaccine_type ? 'First select a vaccine' : 'Select Date'
            "
            class="w-full my-1 text-sm"
          ></date-picker>
        </div>
        <SubmitButton
          class="m-auto"
          :class="[
            !form.date_expired ? 'cursor-not-allowed' : 'cursor-pointer',
          ]"
          title="Turn off reminder(s)"
          width="w-10/12"
        />
      </div>
    </div>
    <div class="mt-6 flex items-center justify-between">
      <div class="pr-1 text-left w-6/12">
        <span class="text-subheaderText">Diagnosing Clinic:</span>
        <input
          type="text"
          class="mt-2 w-full font-proximaLight text-sm p-4 bg-white rounded-md"
          v-model="form.diagnosis_clinic"
        />
      </div>
      <div class="pl-1 text-left w-6/12">
        <span class="text-subheaderText">Clinic's Email Address:</span>
        <input
          type="text"
          class="mt-2 w-full font-proximaLight text-sm p-4 bg-white rounded-md"
          v-model="form.clinic_email_address"
        />
      </div>
    </div>
    <div v-if="!uploadedFileName">
      <SubmitButton
        class="m-auto"
        title="Attach Proof"
        width="w-full"
        @click.native="uploadModals = true"
      />
    </div>
    <div
      v-else
      class="mt-6 flex items-center justify-center font-proximaMedium"
    >
      <span class="mr-2 text-primary">{{ uploadedFileName }}</span>
      <img
        @click="uploadedFileName = ''"
        class="cursor-pointer"
        src="@/assets/images/close-icon.png"
        alt="close-icon"
      />
    </div>
    <div class="mt-8 flex justify-center">
      <BorderedButton
        class="mr-1"
        title="Cancel"
        width="4/12"
        @click.native="cancelSubmit"
      />
      <SubmitButton
        class="ml-1"
        title="Save"
        width="4/12"
        @click.native="saveSubmit"
        :disabled="!editable"
      />
      <!-- :class="[!editable ? 'cursor-not-allowed' : 'cursor-pointer']" -->
    </div>

    <ModalsConfirm :show="uploadModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="uploadModals = false"
        >
          ✖
        </div>
      </div>
      <img
        slot="actionQuestion"
        src="@/assets/images/media-icon.png"
        alt="media-icon"
        class="m-auto"
      />
      <div slot="actionQuestion">
        <h1 class="mt-4 font-bold text-2xl">Scan or Upload New File</h1>
        <p class="mt-2 font-proximaLight text-sm text-subheaderText">
          You may scan a new document or upload an existing one from your device
          (max 3MB).
        </p>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <IconButton
          component="PaperclipIcon"
          iconColor="#fff"
          title="Upload File"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="mr-2 py-4"
          @click.native="openFiles()"
        />
        <IconButton
          component="ScanIcon"
          iconColor="#fff"
          title="Scan New"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="ml-2"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
  </div>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import IconButton from "../../buttons/IconButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import SelectOptions from "../../forms/SelectOptions.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import FormError from "../../warning/FormError.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import { validEmail } from "../../../methods/petMethods";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SubmitButton,
    IconButton,
    BorderedButton,
    SelectOptions,
    DatePicker,
    ModalsConfirm,
    FormError,
  },
  data() {
    return {
      pet: {},
      form: {
        vaccine_type: "",
        vaccine_name: "",
        diagnosis_clinic: "",
        clinic_email_address: "",
        date_applied: null,
        vaccine_evidence: "",
        date_expired: null,
        userEmailAddress: localStorage.getItem("email"),
        petID: "",
      },
      editable: true,
      uploadModals: false,
      uploadedFileName: "",
    };
  },
  computed: {
    ...mapGetters({
      vaccinations: "medicalhistory/getVaccinations",
      vaccineType: "medicalhistory/getVaccineType",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");

    this.pet = JSON.parse(retrievedObject);
    this.form.petID = this.pet.petID;
    this.getVaccineType(this.pet.species);
  },
  methods: {
    ...mapActions({
      getVaccineType: "medicalhistory/getVaccineType",
      addVaccination: "medicalhistory/addVaccination",
      deleteVaccination: "medicalhistory/deleteVaccination",
    }),

    vaccineValue(i) {
      this.form.vaccine_type = i;
    },
    addOneYear(date) {
      // Making a copy with the Date() constructor
      const dateCopy = new Date(date);

      dateCopy.setFullYear(dateCopy.getFullYear() + 1);

      return dateCopy;
    },

    cancelSubmit() {
      this.$router.push({ path: "/addvaccination" });
    },

    saveSubmit() {
      this.editable = false;
      this.$store.commit("errorForm", "");

      let a = this.addOneYear(this.form.date_applied);

      this.form.date_applied = this.form.date_applied
        ? moment(this.form.date_applied).format("YYYY-MM-DD")
        : null;
      this.form.date_expired = this.form.date_expired
        ? moment(this.form.date_expired).format("YYYY-MM-DD")
        : moment(a).format("YYYY-MM-DD");

      let role = localStorage.getItem("role");
      if (this.form.vaccine_type == "") {
        this.$store.commit("errorForm", "Vaccine Type is required");
        return;
      }
      if (!this.form.date_applied) {
        this.$store.commit("errorForm", "Applied is required");
        return;
      }
      if (role == 1 || role == 3) {
        if (!this.form.vaccine_evidence) {
          this.$store.commit("errorForm", "Attach Proof is required");
          return;
        }
      }

      if (
        this.form.clinic_email_address.trim() == "" ||
        !validEmail(this.form.clinic_email_address)
      ) {
        this.$store.commit("errorForm", "Valid Email address required.");
        return;
      }

      console.log(this.$store.state.errorForm ? 1 : 0);
      this.editable = this.$store.state.errorForm ? true : false;

      this.addVaccination(this.form);
    },
    openFiles() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileName(e) {
      this.uploadedFileName = e.target.value;
      let file = e.target.files[0];
      this.form.vaccine_evidence = file;

      this.uploadModals = false;
    },
  },
};
</script>

<style>
.mx-datepicker {
  width: 100%;
}

.mx-input {
  border: none;
  border-bottom: 1px solid rgba(156, 163, 175, 0.2);
  width: 100%;
  box-shadow: none;
  border-radius: 0;
}
</style>
