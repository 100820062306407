<template>
  <div>
    <div class="flex justify-start items-end">
      <div class="">
        <HeaderGlobal
        :titlemenu1="title1"
          titlemenu2="Medical History"
          titlemenu3="Add Vaccination"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center">
      <AddForms />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import AddForms from "./addvaccinationpage/AddForms.vue";

export default {
  components: {
    HeaderGlobal,
    AddForms,
  },
  data() {
    return {
      pet: {},
      title1: "",
    };
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");

    this.pet = JSON.parse(retrievedObject);

    this.title1 = this.pet
      ? this.pet.species.id == 1
        ? this.$t("global_dog") + " Record: " + this.pet.callName
        : this.pet.species.id == 2
          ? this.$t("global_cat") + " Record: " + this.pet.callName
          : this.pet.species.id == 3
            ? this.$t("global_ferret") + " Record: " + this.pet.callName
            : this.pet.species.id == 4
              ? this.$t("global_bird") + " Record: " + this.pet.callName
              : this.$t("global_other") + " Record: " + this.pet.callName
      : "";
  },
};
</script>

<style>
</style>